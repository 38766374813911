/* thead, tbody { display: block !important; } */
/* .chartjs-render-monitor{
  width: 100% !important; */
/* } */
.ant-image-preview-img-wrapper{
  zoom:150% !important;
}
.ant-image-preview-img{
  height: 400px !important; width: 400px !important;
}
.ck-file-dialog-button{
  display: none !important;

}
.ck-dropdown__button{
  display: none !important;
}
picture>img{
  width: 100%!important;
}
.ck-editor__main{
}
.ck-editor{
  border-radius: 0.625rem !important;
  border:1px solid rgb(230, 235, 241) !important;
}
.ck-toolbar{
  border-top-left-radius: 0.625rem !important;
  border-top-right-radius: 0.625rem !important;
  border:1px solid rgb(230, 235, 241) !important;

  /*border:none !important;*/
}
.ck-content{
  border-bottom-left-radius: 0.625rem !important;
  border-bottom-right-radius: 0.625rem !important;
  height: 200px!important;
  border:1px solid rgb(230, 235, 241) !important;

}
.ant-empty-image{
  margin-top: 10px !important;
}
table.custom-table {
  overflow-x: auto !important;
  display: block !important;
}
.notAllowed{
  cursor: not-allowed !important;
}
.ant-skeleton-title{
  width: 100% !important;
}
td.loading-skeleton{
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 10px !important;
  margin-bottom: 0 !important;
}

.ant-skeleton{
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  padding-left: 1px !important;
  padding-right: 1px !important;
}

tbody.custom-body {
    width: 100%;
    overflow-y: auto !important;    /* Trigger vertical scroll    */
    overflow-x: hidden !important;  /* Hide the horizontal scroll */
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph{
  margin-top: 0 !important;

}
.ant-pagination-item, .ant-pagination-total-text .ant-pagination-item{
  padding: 5px !important;
  line-height: normal !important;
}
.ant-pagination-item, .ant-pagination-total-text{
  height: auto !important;
}
.ant-skeleton-content .ant-skeleton-title{
  height: 25px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  vertical-align: middle !important;

}
.ant-pagination-item-active>.custom-page{
  color:white !important;
}
.ant-pagination-item-active{
  background-color: #268756 !important;
  font-weight: normal !important;
}
.ant-pagination-item{
  color: black !important;
}
.middle {
  vertical-align: middle !important;
}
.ant-checkbox{
  top: 0 !important;
}

.ant-select-selection-item-content{
  font-size: 12px !important;
}
.ant-select-multiple .ant-select-selector{
  padding:0px 8.5px;
}

.section-head-order-delivery{
  padding-top: 12px!important;
  padding-left: 12px!important;
  padding-right: 0!important;
  padding-bottom: 0!important;
}

button.ant-custom:focus{
  color:white!important;
}
button.ant-custom:hover{
  color:white!important;
}

.my-tag-delivery{
  background-color: #EB2935;
  border-color: transparent!important;
  color:white;
  border-radius: 4px!important;
}
.my-section-order-delivery{
  padding-left: 12px!important;
  padding-right: 12px!important;
  padding-bottom: 0!important;
}
.section-name-order-delivery{
  background-color: transparent!important;
  box-shadow: none!important;
  border: none!important;
  padding:0!important;
  color: black!important;
  font-weight: bold!important;
}
.btn-order-delivery{
  border-top-left-radius: 0!important;
  border-top-right-radius: 0!important;
  border: none!important;
  color:#268756!important;
  background-color:#E8F4EE!important;
  font-weight: bold!important;

}
.txtRight{
  text-align: right!important;
}
.txtCenter{
  text-align: center!important;
}
/* .ant-input{
  padding: 6px 11px !important;
}
.ant-btn{
  padding: 6px 11px !important;
  height: auto !important;
} */
.ant-table table{
  background-color: transparent !important;
  box-shadow: none !important;
}

/* .ant-input-affix-wrapper{
  padding: 0px 7px 0px 0px !important;
}

.ant-select-multiple .ant-select-selector{
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    padding: 0px 11px !important;
} */

.ant-input-group-addon{
  background-color: white!important;
}
.ant-timerange{
  border-radius: 0!important;
  border-right: none!important;
  border-top-left-radius: 0.625rem!important;
  border-bottom-left-radius: 0.625rem!important;
}

.react-timerange-picker__inputGroup{
  flex-grow:0!important;
  min-width: 0!important;
}
.react-timerange-picker__clock{
  border: 1px solid #268756!important;
  border-radius: 0.625rem!important;
}
.ant-input-disabled, .ant-input[disabled]{
  color: black!important;
}
.react-timerange-picker__clear-button{
  display: none!important;
}
.react-timerange-picker__clock-button{
    display: none!important;
    /* right: 0!important; */
    /* text-align: right!important; */
}
.react-timerange-picker__wrapper{
  border: none!important;
}
.react-timerange-picker__range-divider{
  /* display: none; */
  text-align: center!important;
  margin-left: 10px!important;
  margin-right: 10px!important;

}
.ant-form-item-label>label{
  font-size: 12px!important;
  color: black!important;
  font-weight: bolder!important;
}

.noborder-radius{
  border-radius: 0!important;
}
.bold{
  font-weight: bold;
}
.ant-select-selection-placeholder{
  /* color:red !important; */
  margin-top: 0px !important;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue{
  background-color: transparent !important;
}
.ant-picker, .ant-picker-input > input{
  padding: 8.5 11px;
  font-weight: normal !important;
  color: #525358 !important;
  font-size: 12px !important;
}
.nowrap {
  white-space: nowrap !important;
}

.ant-table-tbody > tr > th{
  white-space: nowrap !important;
  font-weight: bold !important;
  color:#2C2D30 !important;

}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid rgb(230, 235, 241);
  transition: background 0.3s ease 0s;
  color:#525358 !important;
}

.ant-select-multiple .ant-select-selection-item{
  background-color: #3F4254 !important;
  color:white !important;
  border-color:#3F4254 !important;
  font-weight:400;
}
.ant-select-multiple .ant-select-selection-item-remove{
  color: white !important;
}
.ant-form-item-required{
  /* font-size:13px !important; */
  font-weight:500 !important;
  font-size: 14px !important;
  line-height: 1.5;
}
.ant-modal-title{
  font-weight:bold;
}

.ant-form-item-explain-error{
  font-weight: bold!important;
}
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #268756;
}
h1{
  font-size: 20px;
}

.ant-card-head {
  background: #f7f7f7;
  border: 1px solid rgba(221, 223, 226, 0.75);
  font-weight: 600;
}

.ant-table table {
  background: #ffffff;
  border: 1px solid rgba(221, 223, 226, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 0px 30px rgba(82, 63, 105, 0.05);
  /* border-radius: 6px; */
}
html {
  background-color: #ededed;
}
.ant-layout {
  background: #f6f6f6;
}
.ant-layout-sider-children{
  padding-top: 10px;
  padding-bottom: 20px;
}
.ant-pagination-item,
.ant-pagination-total-text .ant-pagination-item {
  border-radius: 20%;
  background-color: #f3f6f9;
  color: #f3f6f9;
}
.ant-pagination-item-active a {
  /* border-radius: 20%; */

  background-color: rgb(38, 135, 86);
}

.chartjs-render-monitor{
  width: 100% !important;
    /* font-family: 'Signika Negative', sans-serif !important; */
}
*{
  /* font-family: 'Signika Negative', sans-serif !important; */
}
#msg{
  margin-bottom: 20px;
}


.ant-menu-title-content {
  font-weight: 600;
}

h1 {
  font-weight: 600;
}

.ant-table table {
  /* border: none; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.ant-table-fixed-header .ant-table-thead > tr > th {
  background: white;
}
.ant-upload.ant-upload-select {
  width: 100%;
}
.ant-input-number {
  width: 100%;
}
.custom-card-trx{
    transition: all .3s ease-in-out;

}
.custom-card-trx:hover{
  /* background-color: #E8F4EE !important; */
  transform: translate(-10px);
  /* box-shadow: #E8F4EE 0px 19px 43px; */
  transform: translate3d(0px, -1px, 0px);
}
@media only screen and (max-width: 1400px) {
.ant-card-type-inner .ant-card-head-title {
    font-size: 12px !important;
  }

  p {
    font-size: 11px;
  }

  a {
    font-size: 12px !important;
  }

  .ant-table {
    font-size: 12px !important;
  }

  .ant-card {
    font-size: 11px !important;
  }

  .ant-table-cell {
    font-size: 12px;
  }

  tspan {
    font-size: 10px;
  }
    .ant-avatar.ant-avatar-icon{
      /* top: 18px; */
    }
    label{
      font-size: 10px!important;
    }
    .ant-select{
      /* font-size: 12px; */
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
      height: 35px!important;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: 35px;

    }
        .ant-form-item {
          font-size: 12px;
        }
}


.button-send-request-wraper{
  background-color:#03753F;
  border: none;
  color: #FFFFFF;
  padding: 12px 100px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  border-radius: 10px;
  position: relative;
}
.button-send-request-wraper:hover{
  background-color: #6F6C6C;
  color: #2C2D30;
  transition: 0.5s;
}

.button-save-draft-wraper{
  background-color: #FFFFFF;
  border:1px solid #03753F;
  color: #03753F;
  padding: 12px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  border-radius: 10px;
  position: relative;
}
.button-save-draft-wraper:hover{
  color: #2C2D30;
  transition: 0.5s;
}

.button-modal-sendreq-wraper{
  background-color: #03753F;
  border:none;
  color: white;
  padding: 12px 70px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  border-radius: 10px;
  position: relative;
}
.button-modal-sendreq-wraper:hover{
  color: #2C2D30;
  transition: 0.5s;
}

.button-delete-item-wraper{
  background-color:#EB2935;
  border: none;
  color: #FFFFFF;
  padding: 12px 100px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  border-radius: 10px;
  position: relative;
}
.button-delete-item-wraper:hover{
  background-color: #6F6C6C;
  color: #2C2D30;
  transition: 0.5s;
}

.button-add-item-wraper{
  background-color: #FFFFFF;
  border:1px solid #03753F;
  color: #03753F;
  padding: 12px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
}
.button-add-item-wraper:hover{
  color: #2C2D30;
  transition: 0.5s;
}

.button-modal-action-wraper{
  background-color: #FFFFFF;
  border:1px solid #03753F;
  color: #03753F;
  padding: 12px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 50%;
}
.button-modal-action-wraper:hover{
  color: #2C2D30;
  transition: 0.5s;
}

.button-email-request-wraper{
  background-color: #03753F;
  border:1px solid #03753F;
  color: #FFFFFF;
  padding: 12px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  border-radius: 30px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
}
.button-email-request-wraper:hover{
  color: #2C2D30;
  transition: 0.5s;
}

.button-email-request-reject-wraper{
  background-color: #EB2935;
  border:1px solid #EB2935;
  color: #FFFFFF;
  padding: 12px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  border-radius: 30px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
}
.button-email-request-reject-wraper:hover{
  color: #2C2D30;
  transition: 0.5s;
}

.button-add-item-quotation-wraper{
  background-color: #FFFFFF;
  border:1px solid #03753F;
  color: #03753F;
  padding: 12px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
}
.button-add-item-quotation-wraper:hover{
  color: #2C2D30;
  transition: 0.5s;
}

.detail-request-header-wraper{
  margin-top: 0px;
  padding-inline: 10%;
}
.detail-request-header-wraper-content{
  padding: 10px 0px 0px;
  background-color: white;
  width: 100%;
  border-radius: 5px;
}
.header-from-input-wraper{
  margin-top: 5px;
}
.header-from-input-wraper-inline{
  padding-inline: 3%;
}
.header-from-input-wraper-pad{
  padding: 10px 0px 0px;
  border: 1px solid #DDDFE2BF;
  border-radius: 5px;
}
.header-from-input-wraper-pad-wrap{
  padding-inline: 10%;
}
.header-from-input-wraper-content{
  padding: 10px 0px 0px;
  background-color: #FFFFFF;
  width: 100%;
  
}
.header-from-input-wraper-content-margin{
  padding-inline: 10%;
}
.header-from-input-wraper-title{
  margin-top: 10px;
}
.header-from-input-wraper-title-text{
  font-size: 18px;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  font-weight: 600;
  color: #2C2D30;
}
.header-from-input-wraper-line{
  padding: 1px 0px 0px;
  background-color: #2C2D30;
  width: 12%;
}
.header-from-input-wraper-title-paragraph-wrap{
  margin-top: 10px;
}
.header-from-input-wraper-title-paragraph{
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  color: #6F6C6C;
}
.header-from-input-wraper-title-label{
  font-size: 14px;
  font-weight: 600;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  color: #2C2D30;
}
.header-from-input-wraper-line-loop{
  padding: 2px 0px 0px;
  background-color: #DDDFE2BF;
  width: 100%;
}

.footer-input-submit-wraper{
  padding: 20px 0px 20px;
  background-color: #FFFFFF;
  margin-top: 1%;
}
.footer-input-submit-wraper-margin{
  padding-inline: 10%;
}
.footer-input-submit-wraper-button{
  padding-inline: 2%;
}

.custom-loader {
  width:70px;
  height:70px;
  border-radius:50%;
  padding:1px;
  background:conic-gradient(#0000 10%,#23905C) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 calc(100% - 8px));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation:s4 1s infinite steps(10);
}
@keyframes s4 {to{transform: rotate(1turn)}}


.navbar {
  padding: 10px 0px 0px;
  background-color: white;
  width: 100%;
  border-radius: 5px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  color: black;
  font-size: 1.5rem;
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar-links a {
  color: black;
  text-decoration: none;
  padding: 0 15px;
  font-size: 1rem;
}

.navbar-links a:hover {
  color: #ffd700;
}

.navbar-toggle {
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
}

.bar {
  background-color: black;
  height: 4px;
  width: 30px;
  transition: 0.4s;
}

@media screen and (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #333;
    z-index: 99;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-toggle {
    display: flex;
  }
}

.tabs-container-wraper {
  display: flex;
  flex-direction: row;
  /* max-width: 600px; */
  margin: 0 auto;
}

.tab {
  /* padding: 10px; */
  border-bottom: solid 1px #BDBDBD;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s;
  /* border-radius: 10px; */
}

.tab:hover {
  background-color: #f0f0f0;
}

.tab.active {
  border-bottom: solid 1px #2C2D30;
}

.tab-content {
  /* padding: 20px;
  margin-top: 10px; */
}

.detail-quotation-content-wraper{
  padding: 10px 0px 0px;
  background-color: white;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
}
.detail-quotation-content-wraper-margin{
  padding-inline: 10px;
}

.data-vendor-wraper{
  padding: 10px 0px 0px;
  background-color: white;
  width: 100%;
}
.data-vendor-wraper-margin{
  padding-inline: 30px;
}

.dashboard-non-anggota-wraper-header{
  padding-inline: 30px;
}
.dashboard-non-anggota-wraper-content{
  padding: 10px 0px 0px;
  background-color: white;
  width: 100%;
  border-radius: 10px;
}
.dashboard-non-anggota-wraper-card{
  padding: 10px 0px 0px;
  background-color: white;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-bottom: 20px;
}
.dashboard-non-anggota-wraper-title{
  font-size: 14px;
  font-weight: 600;
  color: #2C2D30;
}
.dashboard-non-anggota-wraper-flex{
  display: flex;
  align-items: center;
  padding-inline: 30px;
}
.dashboard-non-anggota-wraper-title-delay{
  font-size: 14px;
  font-weight: 600;
  color: #2C2D30;
}
.dashboard-non-anggota-wraper-count-wraper{
  margin-top: 20px;
  padding-inline: 30px;
}
.dashboard-non-anggota-wraper-count-content{
  font-size: 40px;
  font-weight: 600;
  color: #2C2D30;
}

.dashboard-non-anggota-wraper-table{
  margin-top: 20px;
}
.dashboard-non-anggota-wraper-table-content{
  padding: 10px 0px 0px;
  background-color: white;
  width: 100%;
  border-radius: 10px;
}
.dashboard-non-anggota-wraper-table-margin{
  padding-inline: 30px;
}
.dashboard-non-anggota-wraper-table-title{
  display: flex;
}
.dashboard-non-anggota-wraper-table-title-text{
  font-size: 14px;
  font-weight: 600;
  color: #2C2D30;
  margin-top: 10px;
  padding-inline: 30px;
}
.dashboard-non-anggota-loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

/* DATA VENDOR ADMIN NON ANGGOTA */
.button-add-vendor-wraper{
  background-color: #FFFFFF;
  border:1px solid #03753F;
  color: #03753F;
  padding: 12px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
}
.button-add-vendor-wraper:hover{
  color: #2C2D30;
  transition: 0.5s;
}
.form-add-vendor-wraper{
  padding: 10px 0px 0px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.form-add-vendor-wraper-margin{
  padding-inline: 5%;
}

/* DATA PURCHASE ADMIN NON ANGGOTA  */
.button-add-purchase-wraper{
  background-color: #FFFFFF;
  border:1px solid #03753F;
  color: #03753F;
  padding: 12px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
}
.button-add-purchase-wraper:hover{
  color: #2C2D30;
  transition: 0.5s;
}

.form-add-purchase-wraper{
  padding: 10px 0px 0px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  width: 100%;
}
.form-add-purchase-wraper-margin{
  padding-inline: 5%;
}
/* .modal-reposive-horizontal{
  width: 80%;
  max-width: 500px;
  overflow-y: auto;
} */

/* CUSTOM CSS NEWS */
.add-news-wraper{
  padding: 10px 0px 0px;
  width: 100%;
  background-color: white;
  min-height: 660px;
}
.add-news-wraper-margin{
  padding-inline: 2%;
  margin-top: 10px;
}
.tox .tox-statusbar__branding{
  display: none;
}
/* custom.css */
.mce-edit-area {
  width: 100%;
  box-sizing: border-box; 
}

.mce-edit-area iframe {
  width: 100%;
}

/* CUSTOM MODAL */
.modal-custom {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  overflow: auto; 
}

.modal-custom-pr-material {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  overflow: auto; 
}

.modal-content-custom {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  /* max-height: 100vh; */
  margin-top: 20%;
  -webkit-overflow-scrolling: touch; 
}

.modal-content-custom-pr-material {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 5px;
  /* max-width: 80%; */
  width: 550px;
  /* max-height: 100vh; */
  margin-top: 20%;
  -webkit-overflow-scrolling: touch; 
}

.modal-custom::-webkit-scrollbar {
  width: 0; 
  background: transparent; 
}

.close-custom {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-custom:hover,
.close-custom:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* CUSTOM VIEW IMAGE */
.view-image-wraper-custom{
  padding: 20px;
  border: solid #DDDFE2 2px;
  width: 100%;
  border-radius: 5px;
}

/* CUSTOM TABLE INVOICE */
.my-table {
  border-collapse: separate;
  border-spacing: 0 10px; 
  width: 100%;
}

.my-table th,
.my-table td {
  border: 1px solid rgba(221, 223, 226, 0.75);
  text-align: left;
  padding: 8px;
}

.my-table th {
  background-color: white;
  text-align: center;
}

/* CUSTOM DASHBOARD VENDOR NON ANGGOTA */
.custom-dashboard-vendor-non-anggota-wraper{
  padding: 10px 0px 0px;
  background-color: white;
  width: 100%;
  min-height: 900px;
  margin-top: -25px;
}
.custom-dashboard-vendor-non-anggota-title-top-wraper{
  padding-inline: 60px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.custom-dashboard-vendor-non-anggota-title-top-mount{
  margin-left: auto;
}
.custom-dashboard-vendor-non-anggota-title-top{
  font-size: 18px;
}
.custom-dashboard-vendor-non-anggota-card-wraper{
  margin-top: 10px;
  margin: auto;
}
.custom-dashboard-vendor-non-anggota-card{
  padding: 10px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
  margin-bottom: 10px;
  width: 100%;
  min-height: 130px;
  border-radius: 10px;
}
.custom-dashboard-vendor-non-anggota-card-header{
  display: flex;
  align-items: center;
  padding-inline: 15px;
  margin-top: 10px;
}
.custom-dashboard-vendor-non-anggota-card-title{
  font-size: 10px;
}
.custom-dashboard-vendor-non-anggota-card-count-wraper{
  padding-inline: 27px;
}
.custom-dashboard-vendor-non-anggota-card-count{
  font-size: 20px;
}
.custom-dashboard-vendor-non-anggota-table-wraper{
  padding-inline: 50px;
  margin-top: 20px;
}
.custom-dashboard-vendor-non-anggota-table-title{
  font-size: 18px;
}
.custom-dashboard-vendor-non-anggota-chart-wraper{
  padding-inline: 50px;
  margin-top: 20px;
}

/* CUSTOM DASHBOARD VENDOR NON ANGGOTA */